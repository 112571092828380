import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
// import "./Form.css";

const Form = (props) => {
  const history = useHistory();
  // const [tokenVal, setTokenVal] = useState("");

  useEffect(() => {
    const script = document.createElement("script");
    // script.src = "https://d14j3wsk63j4s9.cloudfront.net/js/widget.js";
    script.src = process.env.REACT_APP_WIDGET_URL;
    script.type = "text/javascript";
    script.id = "jsScript";
    script.async = true;
    document.body.appendChild(script);
    console.log("script", process.env.REACT_APP_WIDGET_URL);

    const script1 = document.createElement("script");
    // script1.src ="https://rawgit.com/sitepoint-editors/jsqrcode/master/src/qr_packed.js";
    script1.src = process.env.REACT_APP_QRPACK_URL;
    script1.type = "text/javascript";
    // script1.async = true;
    document.head.appendChild(script1);
  });

  return (
    <>
      <Header />
      <div id="widgetHolder"></div>
    </>
  );
};
export default Form;
