import React from "react";
import { useHistory } from "react-router-dom";

import logout from "../../Assets/logout.png";
import Headerlogo from "../../Assets/Polaris Logo.svg";
import close from "../../Assets/close.png";
// import "./header.css";
import { useState } from "react";

export default function Header() {
  const history = useHistory();
  const [logoutval, setlogoutval] = useState(false);

  const Logout = () => {
    localStorage.clear();
    window.location.replace("/");
  };

  const handleOpen = () => {
    setlogoutval(true);
  };

  const handleClose = () => {
    setlogoutval(false);
  };
  console.log("login", localStorage.getItem("login"));
  return (
    <>
      <div>
        <>
          <div className="header">
            <div>
              <img src={Headerlogo} className="headerlogo" />
            </div>
          </div>
          <div className="headerTitle">PolarisKey QR Code Scanner</div>
          {/* <div
            style={{
              textAlign: "right",
              paddingRight: "4em",
            }}
            className="lgoutdiv"
          >
            <img src={logout} onClick={handleOpen} className="logoutImg" />
          </div> */}
        </>
      </div>
    </>
  );
}
